import CodeExample from "components/docs/code-examples/CodeExample";
import HeaderBar from "components/docs/layout/HeaderBar";
import LINKS from "components/docs/navigation/links";
import NavigationScrollTracker from "components/docs/navigation/NavigationScrollTracker";
import NavigationScrollTrackerWithAnchor from "components/docs/navigation/NavigationScrollTrackerWithAnchor";
import SmallWidthSection from "components/docs/sections/SmallWidthSection";
import UnevenTwoColumnCenteredSection from "components/docs/sections/UnevenTwoColumnCenteredSection";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { StaticFancyPageContainerWithTOC } from "components/docs/shared-components/StaticFancyPageContainer";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

interface TableSectionProps {
  title: string;
  description: string;
  items: Array<{
    property: string;
    type: string;
    description: React.ReactNode;
  }>;
}

// Used to create all the table sections on the left side of the two col
const TABLE_SECTIONS: Array<TableSectionProps> = [
  {
    title: "Shared Properties",
    description:
      "All fields in JSON Schemas returned from Merge have basic properties like “type” and “description” that specify field types.",
    items: [
      {
        property: "type",
        type: "string",
        description: "Denotes the type of data stored in this field.",
      },
      {
        property: "description",
        type: "string",
        description: "If available, will explain what data is expected.",
      },
    ],
  },

  {
    title: "Object Fields",
    description:
      "In addition to shared properties, object fields in JSON Schemas returned from Merge will have these additional properties:",
    items: [
      {
        property: "properties",
        type: "object",
        description:
          "Denotes the object’s key-value pairs where the key is the field name and the value is the sub-schema of the field.",
      },
      {
        property: "required",
        type: "array",
        description:
          "Denotes which object fields are required or optional when making a POST request.",
      },
    ],
  },

  {
    title: "Array Fields",
    description:
      "In addition to shared properties, array fields in JSON Schemas returned from Merge will have these additional properties:",
    items: [
      {
        property: "items",
        type: "object",
        description: (
          <p>
            <code>items</code> is an object describing the <code>type</code> and{" "}
            <code>properties</code> of the items of the array.
          </p>
        ),
      },
    ],
  },

  {
    title: "Enum Fields",
    description:
      "In addition to shared properties, enum fields that have a limited array of string choices will have these additional properties:",
    items: [
      {
        property: "enum_information",
        type: "array",
        description: "Denotes the available choices for enum fields.",
      },
      {
        property: "enum",
        type: "array",
        description: (
          <>
            <p>
              Denotes the available choices for enum fields and adheres to{" "}
              <strong>JSON Schema</strong> convention by only communicating the enum value
              (excluding properties like <code>description</code>).
            </p>
            <p>
              This compliant <code>enum</code> field may be useful for off-the-shelf JSON Schema
              validators, for example.
            </p>
          </>
        ),
      },
    ],
  },
];

const CODE_EXAMPLE_FOLDER = "schema-properties";

/**
 * Small width section with smaller padding above
 */
const ShortSection = styled(SmallWidthSection).attrs({ className: "mb-9" })`
  margin-top: 0px;
`;

/**
 * Creates a single table with header to be used below
 */
const TableSection = ({ title, description, items }: TableSectionProps) => (
  <NavigationScrollTrackerWithAnchor title={title} headingLevel="h3" className="mt-0">
    <p>{description}</p>
    <table className="mb-16">
      <thead>
        <tr>
          <td>Property</td>
          <td>Type</td>
          <td>Description</td>
        </tr>
      </thead>
      <tbody>
        {items.map(({ property, type, description: itemDescription }) => (
          <tr key={property}>
            <td>
              <code>{property}</code>
            </td>
            <td>{type}</td>
            <td>{itemDescription}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </NavigationScrollTrackerWithAnchor>
);

/**
 * Creates an Introduction to Programmatic Writes with Meta Guide
 */
const SchemaProperties = () => (
  <StaticFancyPageContainerWithTOC
    tableOfContents={[
      {
        text: "Overview",
        linkItems: TABLE_SECTIONS.map(({ title }) => ({ text: title })),
      },
    ]}
  >
    <DocsHelmet
      title="Schema Properties at Merge"
      description="High-level overview of JSON Schemas returned from Merge"
    />
    <SmallWidthSection>
      <NavigationScrollTracker>
        <HeaderBar
          title="Schema Properties"
          subtitle="High-level overview of JSON Schemas returned from Merge"
        />
      </NavigationScrollTracker>
    </SmallWidthSection>

    <ShortSection>
      <NavigationScrollTrackerWithAnchor headingLevel="h2" title="Overview">
        <p>
          Merge uses <strong>JSON Schema</strong> conventions in schemas returned from Merge (e.g.,
          the <code>request_schema</code> returned from a <code>/meta</code> endpoint) for scalable
          schemas and programmatic convenience.
        </p>
        <p className="mt-6">In these schemas:</p>
        <ul>
          <li>
            <strong>Shared Properties</strong> specify type definitions with fields like “type” and
            “description”
          </li>
          <li>
            <strong>Object</strong>, <strong>Array</strong> and <strong>Enum Fields</strong> will
            have additional schema properties as described further below
          </li>
        </ul>
        <aside className="mt-6">
          <p>
            For example, when you receive a <code>request_schema</code> from a <code>/meta</code>{" "}
            endpoint, the schema fields specify valid inputs in a POST request to Merge.
          </p>
          <p>
            Learn more in our guide to{" "}
            <Link to={LINKS.MERGE_WRITES_PROGRAMMATIC_INTRO.linkTo}>
              Programmatic Writes with <code>/meta</code>
            </Link>
            .
          </p>
        </aside>
        <aside className="mt-9">
          <p>
            For programmatic validation of these schema properties, we encourage you to refer to the{" "}
            <a
              href="https://json-schema.org/specification.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              JSON Schema specification
            </a>
            .
          </p>
        </aside>
      </NavigationScrollTrackerWithAnchor>
    </ShortSection>

    <UnevenTwoColumnCenteredSection
      className="mt-16"
      columnA={
        <>
          {TABLE_SECTIONS.map(({ title, description, items }) => (
            <TableSection key={title} title={title} description={description} items={items} />
          ))}
        </>
      }
      columnB={
        <CodeExample
          codeBlockName="Example JSON Schema"
          folder={CODE_EXAMPLE_FOLDER}
          colorScheme="light"
          hasLineNumbers={false}
          isVerticallyPadded={false}
        />
      }
    />
  </StaticFancyPageContainerWithTOC>
);

export default SchemaProperties;
